import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Header, Segment } from "semantic-ui-react"

import Layout from "../../components/layout-desktop"
import SEO from "../../components/seo"
import { StyledContainer, StyledImage } from "../../components/core"
import renderAst from "../../components/render-markdown"
import RetrofitFeaturesGrid from "../../components/features/retrofit-features"

const retrofitPage = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(
        fileAbsolutePath: { regex: "/src/content/retrofit/retrofit-intro/" }
      ) {
        htmlAst
        frontmatter {
          image {
            publicURL
          }
          imageAlt
          title
        }
      }
    }
  `)
  const markdownData = data.markdownRemark

  return (
    <Layout>
      <SEO
        title="Retrofit solution - SixEye"
        description="Bring existing projects or unsupported hardware online using SixEye's retrofit solution."
      />

      <Container>
        <Header as="h1" textAlign="center">
          {markdownData.frontmatter.title}
        </Header>
        <StyledContainer text>
          {renderAst(markdownData.htmlAst)}
        </StyledContainer>
        <StyledImage src={markdownData.frontmatter.image.publicURL} alt={markdownData.frontmatter.imageAlt} centered />
        <Header as="h2" textAlign="center">
          Features
        </Header>
        <Segment basic>
          <RetrofitFeaturesGrid columns={3} />
        </Segment>
      </Container>
    </Layout>
  )
}
export default retrofitPage;
